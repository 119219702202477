import ApiService from "@/core/services/api.service";
import LocalService from "@/core/services/local.service";
/*import { destroyToken } from "@/core/services/jwt.service";
import Pusher from "pusher-js";*/

const AppConfiguration = {
  init() {
    /*let pusher = new Pusher(process.env.VUE_APP_PUSHER_API_KEY, {
      cluster: "ap2",
      encrypted: true
    });
    
    pusher.subscribe("genic.app.rebuild");

    pusher.bind("App\\Events\\GenicAppReBuild", () => {
      destroyToken();
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    });*/

    ApiService.get("configuration").then(({ data }) => {
      if (data && data.data) {
        LocalService.saveData("app-config", {
          phoneMask: "+##-##########",
          dateFormat: data.data.date_format,
          dateTimeFormat: data.data.date_format + " " + data.data.time_format,
          timeFormat: data.data.time_format,
          currency: { code: "SGD", symbol: "$" },
          CurrencyFormat: {
            decimal: ".",
            number: ",",
            precision: 2,
            symbol: "$",
          },
          QuantityFormat: { decimal: ".", number: ",", precision: 2 },
          NumberFormat: { decimal: ".", number: ",", precision: 2 },
        });
      }
    });
  },
  get() {
    return LocalService.getData("app-config");
  },
};

export default AppConfiguration;
